export default [{
    title: 'Dashboard',
    route: 'dashboard',
    icon: 'BarChartIcon',
  },
  {
    title: 'Customer',
    route: 'customers-list',
    icon: 'UserIcon',
  },
  {
    title: 'Treatment',
    route: 'treatments-list',
    icon: 'FileTextIcon',
  },
  {
    title: 'Employee',
    route: 'employees-list',
    icon: 'SmileIcon',
  },
]